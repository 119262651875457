.react-datepicker__portal {
  background-color: rgba(0, 0, 0, 0.4);
}
.react-datepicker {
  font-family: "Avenir Next";
  font-size: 16px;
}
.react-datepicker__current-month,
.react-datepicker-time__header {
  font-weight: 500;
  display: none;
}
.react-datepicker__header {
  background-color: rgb(250, 250, 250);
}
.react-datepicker__day--selected {
  background-color: #5d8bff;
  font-weight: 700;
  border-radius: 4px;
}
.react-datepicker__day--outside-month {
  background-color: whitesmoke;
}
.react-datepicker__day--selected:hover {
  background-color: #5580ef;
  border-radius: 4px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: white;
  color: #000;
  display: inline-block;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: whitesmoke;
  color: #000;
  display: inline-block;
}

.react-datepicker__day--today {
  background-color: #e8ebfe;
  color: #383838;
  font-weight: 700;
  border-radius: 4px;
}
.react-datepicker__day--selected.react-datepicker__day--today {
  color: white;
  background-color: #5580ef;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #5d8bff;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: #5580ef;
}
.datepicker.default .datepicker-header,
.datepicker.default .datepicker-navbar-btn {
  color: #5580ef !important;
}

.datepicker.default .datepicker-wheel {
  border-top: 1px solid #5580ef !important;
  border-bottom: 1px solid #5580ef !important;
}

.react-datepicker__portal .react-datepicker__navigation {
  margin-top: 2px;
}
.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  margin-top: 6px;
}
.react-datepicker__portal .react-datepicker__navigation--next {
  margin-top: 8px;
  border-left-color: #cecece;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  margin-top: 8px;
  border-right-color: #cecece;
}

.react-datepicker__year-dropdown-container
  .react-datepicker__year-dropdown-container--select {
  padding-top: 8px;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  border-radius: 4px;
  border: 0;
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgb(0 0 0 / 7%),
    0 1px 1.5px 0 rgb(0 0 0 / 5%);
  background: white;
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  -webkit-appearance: initial;
}

.react-datepicker__portal .react-datepicker__current-month {
  display: none;
}

input.react-datepicker-time__input {
  padding: 8px;
}
.react-datepicker-time__caption {
  font-weight: 500;
}

.react-datepicker-time__input {
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.08);
  border-width: 1px;
  background: white;
  font-size: 14px;
  font-weight: 500;
}

.react-datepicker__input-time-container {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  clear: both;
  width: 100%;
  float: left;
  padding: 10px 0 10px 15px;
  margin: 0;
  text-align: left;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
  display: inline-flex;
}
.react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--select {
  order: 0;
}
.react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--select {
  order: 1;
}
